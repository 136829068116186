import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%" viewBox="0 0 1059.000000 1059.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g 
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd" transform="translate(0.000000,1059.000000) scale(0.100000,-0.100000)">




<path d="M3070 7793 c-24 -43 -70 -121 -100 -173 -31 -52 -137 -234 -235 -405
-98 -170 -217 -377 -265 -460 -85 -145 -465 -803 -540 -935 -31 -55 -191 -331
-555 -960 -40 -69 -98 -170 -130 -225 -32 -55 -90 -156 -130 -225 -40 -69
-115 -199 -167 -290 -52 -91 -121 -210 -153 -265 -114 -195 -145 -250 -145
-257 0 -5 439 -8 975 -8 l975 0 0 460 0 460 -180 0 c-99 0 -180 3 -180 6 0 6
253 447 360 629 26 44 117 202 203 350 275 478 306 530 313 529 5 -1 58 -89
119 -195 61 -107 156 -270 210 -364 54 -93 167 -291 252 -438 84 -148 185
-322 223 -388 39 -66 70 -122 70 -124 0 -3 -121 -5 -270 -5 l-270 0 0 -460 0
-460 1065 0 c586 0 1065 2 1065 5 0 3 -25 49 -56 103 -66 113 -193 334 -359
622 -64 113 -132 230 -150 260 -19 30 -118 201 -221 380 -102 179 -230 399
-282 490 -52 91 -129 224 -170 295 -41 72 -89 155 -107 185 -76 131 -274 475
-340 590 -39 69 -115 199 -167 290 -53 91 -199 345 -326 565 -127 220 -244
422 -259 448 l-29 48 -44 -78z"/>
<path d="M4312 6973 l3 -898 134 -230 c73 -126 137 -234 141 -240 4 -5 28 -46
52 -90 25 -44 71 -125 103 -180 32 -55 99 -172 150 -260 51 -88 118 -205 150
-260 57 -98 114 -197 155 -270 12 -21 76 -132 144 -249 67 -116 178 -308 246
-426 67 -118 132 -230 142 -248 l20 -33 421 3 422 3 3 469 2 468 -22 -8 c-13
-5 -321 -10 -685 -11 l-663 -4 0 381 0 380 645 0 644 0 65 -24 c108 -39 189
-119 229 -226 36 -98 15 -269 -43 -343 -11 -14 -20 -28 -20 -31 0 -3 220 -6
490 -6 l490 0 7 28 c10 35 10 406 0 453 l-7 35 -103 24 c-221 51 -417 152
-594 305 -214 187 -353 388 -432 626 l-27 81 -672 -2 -672 -1 0 381 0 381 672
-1 671 -1 29 83 c79 232 198 411 389 592 51 48 90 88 88 90 -2 2 -29 16 -59
31 -78 39 -168 72 -268 97 -87 22 -91 22 -1265 25 l-1177 4 2 -898z"/>
<path d="M7740 7855 c-431 -67 -828 -390 -994 -809 -15 -38 -39 -119 -54 -180
-24 -100 -26 -130 -26 -306 0 -188 1 -199 33 -315 62 -233 168 -414 345 -591
190 -189 368 -289 626 -351 104 -25 112 -26 630 -32 l525 -6 67 -33 c113 -56
191 -162 208 -285 24 -168 -65 -333 -218 -405 l-57 -27 -1042 -3 -1043 -2 -2
-451 c-2 -248 0 -455 3 -460 3 -6 417 -8 1085 -6 1073 3 1080 3 1176 25 250
57 449 167 632 347 251 248 370 521 382 875 7 188 -9 313 -61 467 -148 441
-537 779 -995 864 -42 8 -247 14 -585 18 l-520 6 -57 29 c-73 37 -151 115
-186 185 -24 50 -27 67 -27 161 0 96 3 110 29 165 38 76 110 149 184 183 l57
27 1045 5 1045 5 3 458 2 457 -1062 -1 c-830 -1 -1082 -4 -1148 -14z"/>
<path d="M7721 6646 c-15 -62 -14 -117 4 -160 8 -20 17 -36 20 -36 3 0 5 54 5
120 0 66 -4 120 -9 120 -5 0 -14 -20 -20 -44z"/>
<path d="M9640 6659 c-13 -6 -39 -21 -57 -34 l-32 -25 -756 0 c-832 0 -787 3
-821 -61 -20 -39 -18 -78 6 -117 39 -64 14 -62 828 -62 739 0 739 0 758 -21
49 -54 147 -75 217 -45 76 31 117 96 117 184 0 76 -38 139 -102 171 -47 22
-115 27 -158 10z"/>
<path d="M3048 5648 c-73 -127 -233 -404 -400 -690 -61 -106 -124 -217 -140
-245 l-30 -53 636 0 635 0 -53 93 c-29 50 -104 182 -168 292 -63 110 -177 308
-253 440 -76 132 -143 248 -150 258 -11 16 -20 5 -77 -95z"/>
<path d="M1513 3149 c-74 -21 -107 -115 -62 -173 12 -15 56 -45 99 -66 77 -38
97 -61 85 -92 -7 -17 -47 -38 -74 -38 -10 0 -38 11 -64 23 l-46 24 -21 -22
c-19 -21 -19 -22 -3 -37 35 -31 83 -48 133 -48 90 0 150 49 150 124 0 59 -22
84 -115 127 -45 21 -85 45 -89 55 -3 9 1 27 9 40 19 29 74 32 120 8 28 -14 30
-14 47 8 17 22 17 24 -3 40 -40 33 -106 44 -166 27z"/>
<path d="M7057 3145 c-85 -30 -127 -100 -127 -210 0 -74 21 -136 56 -167 53
-46 148 -61 210 -32 51 24 61 41 38 64 -19 20 -20 20 -62 -1 -38 -19 -48 -20
-83 -10 -130 39 -106 301 28 301 21 0 49 -7 62 -16 21 -15 24 -15 42 5 25 26
18 38 -35 62 -48 22 -77 23 -129 4z"/>
<path d="M9303 3149 c-48 -14 -83 -62 -83 -113 0 -54 40 -95 122 -127 74 -28
95 -50 82 -87 -15 -44 -69 -52 -133 -20 l-51 25 -22 -24 c-19 -21 -20 -26 -8
-34 105 -70 228 -65 273 10 22 37 22 100 0 130 -9 13 -53 41 -98 62 -46 22
-86 46 -89 55 -4 9 1 27 9 40 19 29 70 32 117 8 31 -16 32 -16 51 7 19 23 18
24 -3 41 -41 34 -107 44 -167 27z"/>
<path d="M2570 2940 l0 -210 129 0 c77 0 132 4 136 10 3 6 3 19 0 30 -6 18
-15 20 -96 20 l-89 0 0 60 0 60 75 0 75 0 0 35 0 35 -75 0 -75 0 0 55 0 55 90
0 90 0 0 30 0 30 -130 0 -130 0 0 -210z"/>
<path d="M3700 2941 c0 -190 2 -209 18 -213 46 -11 52 -1 52 83 l0 80 36 -3
c35 -3 39 -8 78 -80 40 -75 42 -76 84 -80 23 -2 42 -1 42 1 0 2 -20 39 -45 82
-25 43 -45 82 -45 87 0 5 11 15 25 22 57 31 71 143 22 188 -35 33 -72 42 -173
42 l-94 0 0 -209z m198 133 c15 -10 22 -25 22 -48 0 -53 -19 -69 -89 -74 l-61
-4 0 71 0 71 53 0 c31 0 61 -6 75 -16z"/>
<path d="M4805 3138 c2 -7 32 -101 65 -208 33 -107 63 -196 66 -199 5 -6 84
-5 88 0 4 6 126 404 126 412 0 5 -17 7 -37 5 l-37 -3 -45 -167 c-25 -93 -48
-168 -51 -168 -3 0 -26 75 -51 168 l-45 167 -42 3 c-31 2 -41 0 -37 -10z"/>
<path d="M5982 2938 l3 -212 23 1 c12 0 29 1 37 2 13 1 15 31 15 211 l0 210
-40 0 -40 0 2 -212z"/>
<path d="M8102 2938 l3 -212 23 1 c12 0 71 1 130 2 l109 1 -2 28 -1 27 -92 3
-92 3 0 59 0 60 75 0 75 0 0 35 0 35 -75 0 -75 0 0 55 0 55 90 0 90 0 0 30 0
30 -130 0 -130 0 2 -212z"/>
</g>
</svg>



    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
